<template>
  <PageWithHeader class="px-16">
    <template #title><t path="nickname_2">Nickname</t></template>
    <div class="form-item d-f mt-24 px-16">
      <input type="text" v-model.trim="newName" :placeholder='nickName' :maxlength="20" class="input" />
    </div>
    <div style="margin-top:48px;">
      <Button class="btn primary block f-bold f-lg" @click="update">
        <t path="nickname_3" #="{td}" custom>{{ td || 'Save' }}</t>
      </Button>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { nickName, updateNickname } from '@/pages/setting/setting'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Button from '@/components/Button.vue'

export default defineComponent(
  {
    name: 'TheNickName',
    components: { Button, PageWithHeader },
    setup () {
      const router = useRouter()
      const newName = ref('')

      const update = () => {
        updateNickname(newName.value).then(() => {
          router.back()
        })
      }

      return { nickName, newName, update }
    },
  },
)
</script>

<style scoped lang="scss">
  .form-item {
    border: 1px solid #D5DEE8;
    border-radius: 2px;
    background: var(--color-background);
    input {
      background: transparent;
    }
    &::after {
      display: none;
    }
  }
</style>
