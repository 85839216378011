import { updateAvatar } from '@/common/jsBridge.api'
import { showAlert } from '@/components/popup/popup'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { ref } from 'vue'
import { Address, requestUpdateNickname, uploadAvatar } from './setting.api'

// 修改名称
export const updateNickname = (nickName: string) => {
  return requestUpdateNickname({ nickName }).then(() => {
    localSet(keymap.user.nickName, nickName)
  })
}

export const nickName = localGet(keymap.user.nickName)

export type AvatarResp = {
  url: string;
}

export const useUpdateAvatar = (old: string | null) => {
  const avatar = ref(old)
  const update = () => updateAvatar().then(resp => {
    avatar.value = (resp as AvatarResp)?.url
    localSet(keymap.user.avatar, avatar.value)
    uploadAvatar({
      url: (resp as AvatarResp)?.url,
    })
  }).catch(() => {
    showAlert('Avatar update failed!')
  })
  return [avatar, update]
}

export const getAddressSchema = (data: Address) => {
  return {
    recipientName: {
      pattern: 'noSpecial',
      default: data.recipientName,
    },
    recipientMobile: {
      pattern: /^\d{9,12}$/,
      default: data.recipientMobile,
    },
    recipientCity: {
      pattern: 'noSpecial',
      default: data.recipientCity,
    },
    recipientProvince: {
      pattern: 'noSpecial',
      default: data.recipientProvince,
    },
    recipientZipCode: {
      pattern: 'noSpecial',
      default: data.recipientZipCode,
    },
    recipientAddress: {
      pattern: 'noSpecial',
      default: data.recipientAddress,
    },
  }
}
